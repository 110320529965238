/* eslint-disable class-methods-use-this */
/* eslint-disable no-shadow */
import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import { logEvent } from 'utils/analyticsTracking';
import DeliveryMethods from './DeliveryMethods';
import AlertPreferences from './AlertPreferences';
import SnackBarAlert from '../../Shared/SnackBarAlert';
import './manage-alert.scss';
import api from '../../../utils/api';
import { BusContext } from '../../Contexts/BusContext';
import { AuthContext } from '../../Contexts/AuthContext';

let busContextData = null;

class ManageAlerts extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            initialLoading: true,
            receiveEmail: true,
            immediate: true,
            immediateInitial: true,
            serviceWithin3Days: false,
            serviceWithin3DaysInitial: false,
            serviceWithin7Days: false,
            serviceWithin7DaysInitial: false,
            nextPlanned: false,
            nextPlannedInitial: false,
            customerId: '',
            productName: 'Connected Diagnostics',
            notifType: 'EMAIL',
            userEmail: '',
            userEmailInitial: '',
            loading: false,
            userFK: '',
            firstName: '',
            lastName: '',
            showSnackbar: false,
            toastMessage: '',
            messageClassName: '',
            isUpdateButtonDisabled: true
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0
        });

        const userData = this.context;
        const userId = userData.userID;
        const userEmail = userData.username || '';
        const customer = userData.customerList;
        const firstName = userData.givenName;
        const lastName = userData.familyName;

        this.setState(
            {
                customerId: customer,
                userFK: userId,
                userEmail,
                firstName,
                lastName
            },
            () => {
                const {
                    customerId,
                    productName,
                    notifType,
                    userEmail,
                    userFK,
                    firstName,
                    lastName
                } = this.state;
                const data = {
                    customerSK: customerId,
                    productName,
                    notifType,
                    userFK,
                    firstName,
                    lastName,
                    emailAddress: userEmail
                };
                api.manageAlerts.get(data).then(res => {
					const newState = res.data;
                    this.setState({
                        immediate: newState.selectedReports.stopNow === 'true',
                        immediateInitial: newState.selectedReports.stopNow === 'true',
                        serviceWithin3Days:
                            newState.selectedReports.serviceNow === 'true',
                        serviceWithin3DaysInitial:
                            newState.selectedReports.serviceNow === 'true',
                        serviceWithin7Days:
                            newState.selectedReports.serviceSoon === 'true',
                        serviceWithin7DaysInitial:
                            newState.selectedReports.serviceSoon === 'true',
                        nextPlanned:
                            newState.selectedReports.information === 'true',
                        nextPlannedInitial:
                            newState.selectedReports.information === 'true',
                        receiveEmail: newState.emailResponseFlag !== undefined ? newState.emailResponseFlag !== 'false' : true,
                        userEmail: newState.email,
                        userEmailInitial : newState.email,
                        initialLoading: false
                    });
                });

                

                
            }
        );
    }

    onChangeEmail = e => {
        this.setState({
            userEmail: e.target.value,
            isUpdateButtonDisabled: false
        });
    };

    updateState = value => {
        this.setState({ ...value, isUpdateButtonDisabled: false });
    };

    updateSeverityInfo = () => {
        logEvent({
          action: 'click',
          category: 'Manage Alerts',
          label: 'update'
        });
        const {
            customerId,
            immediate,
            immediateInitial,
            serviceWithin3Days,
            serviceWithin3DaysInitial,
            serviceWithin7Days,
            serviceWithin7DaysInitial,
            nextPlanned,
            nextPlannedInitial,
            userEmail,
            userEmailInitial,
            receiveEmail,
            userFK
        } = this.state;

        
        
        this.setState(
            {
                loading: true
            },
            () => {
                const data = {
                    selectedReports: {
                        serviceNow: serviceWithin3Days,
                        information: nextPlanned,
                        stopNow: immediate,
                        serviceSoon: serviceWithin7Days
                    },
                    customerSK: customerId,
                    emailResponseFlag: receiveEmail ? '1' : '0',
                    userId: userFK,
                    email: userEmail
                };

                api.manageAlerts
                    .post(data)
                    .then(() => {
                        busContextData.getAllData();
                        this.setState({
                            loading: false,
                            showSnackbar: true,
                            toastMessage: 'Preferences updated successfully.',
                            messageClassName: '',
                            isUpdateButtonDisabled: true
                        });
                    })
                    .catch(() => {
                        this.setState({
                            loading: false,
                            showSnackbar: true,
                            toastMessage: 'Preferences update failed.',
                            messageClassName: 'error'
                        });
                    });
                    
                    if (immediate!==immediateInitial) 
                    {
                        const ek=immediate?'faultalert_subscribed':'faultalert_unsubscribed';
                        this.sendEventToMetaCx(ek,'immediate');
                        this.setState({ immediateInitial: immediate });
                    }
                    
                    if (serviceWithin3Days!==serviceWithin3DaysInitial) 
                    {
                        const ek=serviceWithin3Days?'faultalert_subscribed':'faultalert_unsubscribed';
                        this.sendEventToMetaCx(ek,'servicewithin3days');
                        this.setState({ serviceWithin3DaysInitial: serviceWithin3Days });
                    }

                    if (serviceWithin7Days!==serviceWithin7DaysInitial) 
                    {
                        const ek=serviceWithin7Days?'faultalert_subscribed':'faultalert_unsubscribed';
                        this.sendEventToMetaCx(ek,'servicewithin7days');
                        this.setState({ serviceWithin7DaysInitial: serviceWithin7Days });
                    }

                    if (nextPlanned!==nextPlannedInitial) 
                    {
                        const ek=nextPlanned?'faultalert_subscribed':'faultalert_unsubscribed';
                        this.sendEventToMetaCx(ek,'nextplannedservice');
                        this.setState({ nextPlannedInitial: nextPlanned });
                    }

                    if (userEmail!==userEmailInitial) 
                    {
                        this.sendEventToMetaCx('faultalertemail_updated','email');    
                        this.setState({ userEmailInitial: userEmail });
                    }
            }
        );
    };

    sendEventToMetaCx(ek, at)
    {
        const customer = this.context.customerList;

        api.metaCx.addCommment({
            userId: this.context.userID,
            eventKey: ek,
            source: 'external',
            eventDate: Date.now(),
            companyName: '',
            companyId: customer,
            alertType: at,
            busName: ''
            }).then((response)=>{
            if (response?.status !== 204)
            {
                console.log(`metaCx did not return 204 for ${ek}`);
            }
            });
    }

    saveBusContextData(context) {
        busContextData = context;
    }

    render() {
        const {
            receiveEmail,
            immediate,
            serviceWithin3Days,
            serviceWithin7Days,
            nextPlanned,
            userEmail,
            loading,
            initialLoading,
            showSnackbar,
            toastMessage,
            messageClassName
        } = this.state;

        return (
            <BusContext.Consumer>
                {busContext => (
                    <div
                        className='manage-alert-container'
                        style={loading ? { opacity: 0.5 } : {}}
                    >
                        {busContext &&
                            busContext.customerId &&
                            this.saveBusContextData(busContext)}
                        {initialLoading ? (
                            <div className='page-loader'>
                                <CircularProgress />
                            </div>
                        ) : (
                            <Fragment>
                                {showSnackbar && (
                                    <SnackBarAlert
                                        className='manage-alert-snackbar'
                                        message={toastMessage}
                                        messageClassName={messageClassName}
                                    />
                                )}
                                <Paper className='manage-alert-card-container'>
                                    <DeliveryMethods
                                        onChange={this.updateState}
                                        isChecked={receiveEmail}
                                        userEmail={userEmail}
                                        onChangeEmail={this.onChangeEmail}
                                    />
                                    {loading && (
                                        <div className='page-loader'>
                                            <CircularProgress />
                                        </div>
                                    )}
                                    <AlertPreferences
                                        onChange={this.updateState}
                                        immediate={immediate}
                                        serviceWithin3Days={serviceWithin3Days}
                                        serviceWithin7Days={serviceWithin7Days}
                                        nextPlanned={nextPlanned}
                                        updateSeverityInfo={
                                            this.updateSeverityInfo
                                        }
                                        loading={loading}
                                        isUpdateButtonDisabled={
                                            this.state.isUpdateButtonDisabled
                                        }
                                    />
                                </Paper>
                            </Fragment>
                        )}
                    </div>
                )}
            </BusContext.Consumer>
        );
    }
}
export default ManageAlerts;
